<template>
  <div class="waiting-list-form">
    <div class="columns">
      <div class="column col-12 form-group"
           :class="{'has-error': $v.form.scheduleId.$error}">
        <label for="schedule" class="form-label">Agenda</label>
        <select id="schedule" class="form-select"
                v-model="form.scheduleId"
                @change="loadScheduleConfig"
                @blur="$v.form.scheduleId.$touch()">
          <option value="">Selecione</option>
          <option v-for="item in schedules"
                  :value="item.id" :key="item.id">{{ item.name }}
          </option>
        </select>
      </div>
      <div class="column col-12 form-group"
           :class="{'has-error': $v.form.professionalId.$error}">
        <label for="professional" class="form-label">Profissional</label>
        <select id="professional" class="form-select"
                v-model="form.professionalId"
                @change="loadInsurances"
                @blur="$v.form.professionalId.$touch()">
          <option value="">Selecione</option>
          <option v-for="item in professionals"
                  :value="item.id" :key="item.id">{{ item.name }}
          </option>
        </select>
      </div>
      <div class="column col-12 form-group"
           :class="{'has-error': $v.form.patient.name.$error}">
        <label class="form-label">
          Paciente
        </label>
        <dx-autocomplete
          v-model="patient"
          :source="findPatient"
          label="name"
          track-by="id"
          @input="setPatient"
          @blur="onPatientBlur"
          placeholder="Nome do paciente, data de nascimento ou CPF"
          input-id="appointment-patient"
          :readonly="!!form.patient.id"
          :debounce="800"
          ref="patientSearch">
          <button slot="action" @click="unsetPatient"
                  class="btn btn-action input-group-btn btn-icon"
                  :class="patient ? 'btn-gray' : 'btn-neutral'"
                  tabindex="-1">
            <fa-icon :icon="['fal', patient ? 'times' : 'search']"></fa-icon>
          </button>
          <template v-slot="{ item }">
            <a v-if="item.id">{{ item.name }} ({{ item.birthDate | date }})</a>
            <a v-else>{{ item.name }} (não cadastrado)</a>
          </template>
        </dx-autocomplete>
      </div>
      <div class="column col-12 form-group"
           :class="{'has-error': $v.form.patient.insurancePlanId.$error}">
        <label for="insurance" class="form-label">Convênio</label>
        <select id="insurance" class="form-select"
                @blur="$v.form.patient.insurancePlanId.$touch()"
                v-model="form.patient.insurancePlanId">
          <option value="">Selecione</option>
          <option v-for="(item, i) in insurances"
                  :value="item.plan.id" :key="i">{{ item.name }}
          </option>
        </select>
      </div>
      <div class="column col-6 form-group"
           :class="{'has-error': $v.form.patient.cellphone.$error}">
        <label for="cellphone" class="form-label">Celular</label>
        <input type="text" id="cellphone" class="form-input"
               v-model="form.patient.cellphone"
               @blur="$v.form.patient.cellphone.$touch()"
               placeholder="(00) 0 0000-0000" v-mask-phone.br>
      </div>
      <div class="column col-6 form-group"
           :class="{'has-error': $v.form.patient.phone.$error}">
        <label for="phone" class="form-label">Residencial</label>
        <input type="text" id="phone" class="form-input"
               v-model="form.patient.phone"
               @blur="$v.form.patient.phone.$touch()"
               placeholder="(00) 0000-0000" v-mask-phone.br>
      </div>
      <div class="column col-12 form-group">
        <label for="notes" class="form-label">Observações</label>
        <textarea id="notes" rows="2" class="form-input"
                  v-model="form.notes"/>
      </div>
      <div class="column col-12 text-right">
        <button class="btn btn-primary btn-icon-right btn-block"
                @click="save">
          <span v-if="form.id">Atualizar informações</span>
          <span v-else>Adicionar na lista de espera</span>
          <span></span>
          <fa-icon class="ml-2" :icon="['fal', 'arrow-right']"/>
        </button>
        <button class="btn btn-gray btn-block mt-2" @click="cancel">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mergeFrom } from '@/helpers/object';
import { required } from 'vuelidate/lib/validators';
import { phone } from '@/data/validators';
import formMixin from '@/mixins/form';

export default {
  mixins: [formMixin],
  props: {
    data: {
      id: {
        type: String,
      },
      scheduleId: {
        type: String,
      },
      professionalId: {
        type: String,
      },
      patient: {
        id: {
          type: String,
        },
        name: {
          type: String,
        },
        phone: {
          type: String,
        },
        cellphone: {
          type: String,
        },
        insurancePlanId: {
          type: String,
        },
      },
      notes: {
        type: String,
      },
    },
  },
  data() {
    return {
      path: '/waiting-list',
      patient: null,
      schedules: this.$store.state.appointment.filter.schedules,
      professionals: [],
      insurances: [],
      form: this.blankForm(),
    };
  },
  validations() {
    return {
      form: {
        scheduleId: { required },
        professionalId: { required },
        patient: {
          name: { required },
          insurancePlanId: { required },
          phone: { phone },
          cellphone: { required, phone },
        },
      },
    };
  },
  watch: {
    'data.id': function load() {
      if (this.data.id) {
        this.patient = {
          id: this.data.patient.id,
          name: this.data.patient.name,
        };
        this.form = {
          id: this.data.id,
          scheduleId: this.data.schedule.id,
          professionalId: this.data.professional.id,
          patient: {
            id: this.data.patient.id,
            name: this.data.patient.name,
            phone: this.data.patient.phone,
            cellphone: this.data.patient.cellphone,
            insurancePlanId: this.data.insurance.plan.id,
          },
          notes: this.data.notes,
        };
        this.loadInsurances();
      }
    },
  },
  methods: {
    loadItems() {
      this.$emit('schedule', this.form.scheduleId);
    },
    loadScheduleConfig() {
      this.clearForm();
      this.loadItems();

      if (!this.form.scheduleId) {
        return null;
      }

      return this.$http
        .get(`/schedules/${this.form.scheduleId}/professionals`)
        .then(({ data }) => {
          this.professionals = data.items;
        })
        .catch(() => {});
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      const data = this.clone(this.form);
      data.patient.phone = this.onlyNumbers(data.patient.phone);
      data.patient.cellphone = this.onlyNumbers(data.patient.cellphone);

      const request = !data.id
        ? this.$http.post(this.path, data)
        : this.$http.put(`${this.path}/${data.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(() => {
          this.clearForm();
          this.$toast.show('Registro salvo');
          this.$emit('save', this.form.scheduleId);
        })
        .catch(() => {});
    },
    cancel() {
      this.clearForm();
      this.$emit('cancel');
    },
    loadInsurances() {
      if (!this.form.professionalId) {
        return;
      }
      this.insurances = this.professionals
        .find(({ id }) => id === this.form.professionalId).insurances;
    },
    findPatient(search) {
      return this.$http.get(`/patients?search=${search}`)
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        });
    },
    setPatient(patient) {
      const mergeData = patient || this.blankForm().patient;
      mergeFrom(this.form.patient, mergeData);
      if (patient && patient.id) {
        this.$http.get(`/patients/${patient.id}`)
          .then(({ data }) => {
            this.form.patient.id = data.id;
            this.form.patient.name = data.name;
            this.form.patient.phone = data.phone;
            this.form.patient.cellphone = data.cellphone;
            const insurance = this.insurances
              .find(({ plan }) => plan.id === data.patient.insurance.planId);
            if (insurance) {
              this.form.patient.insurancePlanId = insurance.plan.id;
            }
          })
          .catch(() => {});
      }
    },
    unsetPatient() {
      this.setPatient(null);
      this.patient = null;
    },
    onPatientBlur(text) {
      this.form.patient.name = text;
      this.$v.form.scheduleId.$touch();
    },
    clearForm() {
      this.unsetPatient();
      this.form.id = '';
      this.form.professionalId = '';
      this.form.patient = {
        id: '',
        name: '',
        phone: '',
        cellphone: '',
        insurancePlanId: '',
      };
      this.form.notes = '';
      this.$v.form.$reset();
    },
    blankForm() {
      return {
        id: '',
        scheduleId: '',
        professionalId: '',
        patient: {
          id: '',
          name: '',
          phone: '',
          cellphone: '',
          insurancePlanId: '',
        },
        notes: '',
      };
    },
  },
};
</script>

<style lang="scss">
  @import '../../../../../assets/scss/variables';
  .waiting-list-form {
  }
</style>
