<template>
  <div class="search-appointment-modal">
    <dx-modal title="Pesquisar agendamento" size="lg"
              :value="show" @input="close" id="modal-search-appointment">
      <div class="columns">
        <div class="column col-12 form-group"
             :class="{'has-error': $v.filters.search.$error}">
          <label class="form-label">Parte do nome do paciente</label>
          <div class="input-group">
            <input type="text" class="form-input"
                   id="search" v-model="filters.search"
                   @keypress.enter="load" v-focus
                   placeholder="Informe o nome e tecle [ENTER]">
            <button class="btn btn-primary btn-icon btn-icon-left input-group-btn"
                    :disabled="loading" :class="{loading}" @click="load">
              <fa-icon :icon="['fal', 'search']"></fa-icon>Pesquisar
            </button>
          </div>
          <template v-if="$v.filters.search.$error">
            <div class="form-input-hint"
                 v-if="!$v.filters.search.required">Campo obrigatório</div>
          </template>
        </div>
        <div class="column col-3 col-sm-12 form-group"
             :class="{'has-error': $v.filters.startDate.$error}">
          <label class="form-label">Data inicial</label>
          <dx-input-date
            id="start-date"
            class="form-input text-center"
            v-model="filters.startDate"
            @blur="$v.filters.startDate.$touch()"
          />
          <template v-if="$v.filters.startDate.$error">
            <div class="form-input-hint"
                 v-if="!$v.filters.startDate.required">Campo obrigatório</div>
            <div class="form-input-hint"
                 v-else-if="!$v.filters.startDate.date">Data inválida</div>
          </template>
        </div>
        <div class="column col-3 col-sm-12 form-group"
             :class="{'has-error': $v.filters.endDate.$error}">
          <label class="form-label">Data final</label>
          <dx-input-date
            id="end-date"
            class="form-input text-center"
            v-model="filters.endDate"
            @blur="$v.filters.endDate.$touch()"
          />
          <template v-if="$v.filters.endDate.$error">
            <div class="form-input-hint"
                 v-if="!$v.filters.endDate.required">Campo obrigatório</div>
            <div class="form-input-hint"
                 v-else-if="!$v.filters.endDate.date">Data inválida</div>
            <div class="form-input-hint"
                 v-else-if="!$v.filters.endDate.minDate">Data menor que inicial</div>
          </template>
        </div>
        <div class="column col-6 form-group">
          <label class="form-label">Profissional</label>
          <select id="professional" class="form-select"
                  v-model="filters.professionalId">
            <option value="">Todos os profissionais</option>
            <option v-for="(item, i) in professionals"
                    :value="item.id" :key="i">{{ item.name }}</option>
          </select>
        </div>
      </div>
      <template v-if="appointments.length === 0">
        <div class="empty mt-2">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">{{ executed ? 'Não encontrado' : 'Pesquisar' }}</p>
          <p class="empty-subtitle" v-if="executed">
            Nenhum paciente encontrado com este nome. Tente novamente
          </p>
          <p class="empty-subtitle" v-else>
            Informe o nome ou parte do nome do paciente para realizar a busca
          </p>
        </div>
      </template>
      <template v-else>
        <h6 class="h6 mt-2">Resultado da busca</h6>
        <table class="table table-hover">
          <thead>
          <tr>
            <th>Data / Status</th>
            <th>Nome / Telefone</th>
            <th>Convênio / Tipo</th>
            <th>Agenda / Profissional</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in appointments" :key="i"
              class="c-hand" @click="selectAppointment(item)">
            <td>
              <div>{{ item.date | date('DD/MM/YYYY HH:mm')}}</div>
              <div><small>{{ status.getName(item.status) }}</small></div>
            </td>
            <td>
              <div>
                <strong class="text-info">
                  {{ item.patient.name | capitalizeName }}
                </strong>
              </div>
              <div><small>{{ item.patient.cellphone | phone }}</small></div>
            </td>
            <td>
              <div>{{ item.insuranceName }}</div>
              <div><small>{{ type.getName(item.type) }}</small></div>
            </td>
            <td>
              <div>{{ item.scheduleName | capitalizeName }}</div>
              <small>{{ item.professionalName | capitalizeName }}</small>
            </td>
            <td class="text-right"><fa-icon :icon="['fal', 'chevron-right']"/></td>
          </tr>
          </tbody>
        </table>
      </template>
      <template slot="footer">
        <button class="btn" @click="close">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import * as status from 'src/data/appointment-statuses';
import * as type from '@/data/appointment-types';
import { mapMutations, mapState } from 'vuex';
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '../../../../data/validators';

export default {
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      status,
      type,
      loading: false,
      executed: false,
      professionals: [],
      appointments: [],
      filters: this.blankFilters(),
      filter: this.$store.state.appointment.filter,
    };
  },
  validations() {
    const rules = {
      filters: {
        startDate: { required, date },
        endDate: { required, date },
        search: { required },
      },
    };

    if (this.filters.startDate && moment(this.filters.startDate).isValid()) {
      rules.filters.endDate = {
        required,
        date,
        minDate: minDate(this.filters.startDate),
      };
    }

    return rules;
  },
  mounted() {
    this.loadProfessionals();
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  methods: {
    ...mapMutations({
      hideSearchAppointmentModal: 'Appointment.HIDE_SEARCH_APPOINTMENT_MODAL',
    }),
    close() {
      this.$emit('close');
    },
    async load() {
      this.$v.filters.$touch();
      if (this.$v.filters.$error) {
        return;
      }

      this.loading = true;

      const params = {
        limit: 100,
        metaOrigin: 'call_center',
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
        search: this.filters.search,
        insurancePlanIds: this.user.insurancePlanIds.join(','),
      };

      if (this.filters.professionalId) {
        params.professionalId = this.filters.professionalId;
      }

      await this.$http.get('/appointment-search', { params })
        .then(({ data }) => {
          this.appointments = data.items
            .map(item => ({
              id: item.id,
              type: item.type,
              status: item.status,
              date: item.startDate,
              scheduleId: item.schedule
                ? item.schedule.id
                : '',
              scheduleName: item.schedule
                ? item.schedule.name
                : 'Agenda não importada',
              professionalName: item.professional
                ? item.professional.name
                : 'Profissional não importada',
              patient: {
                name: item.patient.name,
                birthDate: item.patient.birthDate,
                gender: item.patient.gender,
                cellphone: item.patient.cellphone,
              },
              insuranceName: item.insurance.name,
            }));
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.executed = true;
          this.loading = false;
        });
    },
    loadProfessionals() {
      const params = {
        limit: 0,
      };

      return this.$http
        .get('/professionals', { params })
        .then(({ data }) => {
          this.professionals = data.items.map(item => ({
            id: item.id,
            name: item.name,
          }));
        })
        .catch(() => {});
    },
    selectAppointment(item) {
      this.filter.scheduleId = item.scheduleId;
      this.filter.insuranceId = '';
      this.filter.expenseId = '';
      this.filter.date = item.date;
      this.filter.search = item.patient.name;
      this.hideSearchAppointmentModal();
    },
    blankFilters() {
      return {
        search: '',
        professionalId: '',
        startDate: moment().subtract(6, 'month').format('YYYY-MM-DD'),
        endDate: moment().add(9, 'month').format('YYYY-MM-DD'),
      };
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
.search-appointment-modal {
}
</style>
