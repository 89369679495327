import { STATUS_AVAILABLE, STATUS_BLOCKED } from '@/data/appointment-statuses';

export const options = [
  {
    type: 'new',
    name: 'Agendar',
    icon: ['fal', 'plus'],
    verify: ({ status }) => status === STATUS_AVAILABLE,
  },
  {
    type: 'edit',
    name: 'Editar',
    icon: ['fal', 'edit'],
    verify: ({ id, status }) => (
      !!id && ![STATUS_AVAILABLE, STATUS_BLOCKED].includes(status)
    ),
  },
  {
    type: 'copy',
    name: 'Copiar',
    icon: ['fal', 'copy'],
    verify: ({ id, status }) => !!id && status !== STATUS_BLOCKED,
  },
  {
    type: 'cut',
    name: 'Recortar',
    icon: ['fal', 'cut'],
    verify: ({ id, status }) => !!id && status !== STATUS_BLOCKED,
  },
  {
    type: 'paste',
    name: 'Colar',
    icon: ['fal', 'paste'],
    verify: ({ status }, clipboard) => (
      status === STATUS_AVAILABLE && clipboard.data !== null
    ),
  },
  {
    type: 'delete',
    name: 'Excluir',
    icon: ['fal', 'trash'],
    verify: ({ id, status }) => (
      !!id && ![STATUS_AVAILABLE, STATUS_BLOCKED].includes(status)
    ),
  },
  // {
  //   type: 'lock',
  //   name: 'Bloquear',
  //   icon: ['fal', 'lock'],
  //   verify: ({ status }) => status === STATUS_AVAILABLE,
  // },
  // {
  //   type: 'unlock',
  //   name: 'Desbloquear',
  //   icon: ['fal', 'unlock'],
  //   verify: ({ id, status }) => !!id && status === STATUS_BLOCKED,
  // },
];

export default options;
