export const types = {
  assessment: 'Avaliação',
  surgery: 'Cirurgia',
  consultation: 'Consulta',
  exam: 'Exame',
  laboratory: 'Laboratorial',
  prenatal: 'Pré-natal',
  procedure: 'Procedimento',
  return: 'Retorno',
  session: 'Sessão',
};

export function getName(value) {
  return types[value] || '-';
}
