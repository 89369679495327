export const STATUS_SCHEDULED = 'scheduled';
export const STATUS_BLOCKED = 'blocked';
export const STATUS_AVAILABLE = 'available';

export const statuses = [
  {
    key: STATUS_SCHEDULED,
    name: 'Agendado',
    color: '#F1E87A',
  },
  {
    key: STATUS_BLOCKED,
    name: 'Bloqueado',
    color: '#9B9898',
  },
  {
    key: 'patient_canceled',
    name: 'Cancelado pelo paciente',
    color: '#ff9696',
  },
  {
    key: 'professional_canceled',
    name: 'Cancelado pelo profissional',
    color: '#ff9696',
  },
  {
    key: 'confirmed',
    name: 'Confirmado',
    color: '#00B6AE',
  },
  {
    key: STATUS_AVAILABLE,
    name: 'Disponível',
    color: '#F9F9F9',
  },
  {
    key: 'in_attendance',
    name: 'Em Atendimento',
    color: '#20F8FF',
  },
  {
    key: 'waiting',
    name: 'Em Espera',
    color: '#E8A363',
  },
  {
    key: 'missed',
    name: 'Falta',
    color: '#f85c5c',
  },
  {
    key: 'finished',
    name: 'Finalizado',
    color: '#3197FF',
  },
  {
    key: 'report',
    name: 'Laudo',
    color: '#30FF9B',
  },
  {
    key: 'payment',
    name: 'Pagamento',
    color: '#B429FF',
  },
  {
    key: 'rescheduled',
    name: 'Remarcado',
    color: '#E676FF',
  },
  {
    key: 'screening',
    name: 'Triagem',
    color: '#B4FF00',
  },
];

export function get(key) {
  return statuses.find(item => item.key === key);
}

export function getColor(key) {
  const status = get(key);
  return status ? status.color : null;
}

export function getName(key) {
  const status = get(key);
  return status ? status.name : null;
}

export default statuses.filter(({ key }) => !['available', 'blocked'].includes(key));
