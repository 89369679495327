export const councils = {
  CBOO: 'Conselho Brasileiro de Óptica e Optometria',
  COREN: 'Conselho Regional de Enfermagem',
  CRAS: 'Conselho Regional de Assistência Social',
  CRBM: 'Conselho Regional de Biomedicina',
  CREF: 'Conselho Regional de Educação Física',
  CREFITO: 'Conselho Regional de Fisioterapia e Terapia Ocupacional',
  CRF: 'Conselho Regional de Farmácia',
  CRFA: 'Conselho Regional de Fonoaudiologia',
  CRM: 'Conselho Regional de Medicina',
  CRN: 'Conselho Regional de Nutrição',
  CRO: 'Conselho Regional de Odontologia',
  CRP: 'Conselho Regional de Psicologia',
  CRTR: 'Conselho Regional de Técnicos em Radiologia',
  ABQ: 'Associação Brasileira de Quiropraxia',
  RMS: 'Registro do Ministério da Saúde',
};

export function formatCouncil(value) {
  if (!value || !('record' in value)) {
    return '';
  }
  return `${value.name}-${value.state} ${value.record}`;
}
