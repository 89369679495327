<template>
  <div class="waiting-list-modal">
    <dx-modal title="Lista de espera"
              :value="show" size="lg"
              @input="close" id="modal-waiting-list">
      <div class="columns">
        <div class="column col-4 form-group">
          <waiting-list-form
            :data="data"
            @cancel="cancel"
            @save="save"
            @schedule="loadSchedule" />
        </div>
        <div class="column col-8 form-group">
          <waiting-list-items ref="modalItem"
            :scheduleId="scheduleId"
            :item="item"
            @edit="edit"
          />
        </div>
      </div>
      <template slot="footer">
        <button class="btn" @click="close">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import WaitingListForm from './Form.vue';
import WaitingListItems from './Items.vue';

export default {
  components: {
    WaitingListForm,
    WaitingListItems,
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      scheduleId: '',
      cancelItemId: '',
      data: {},
      item: {},
    };
  },
  methods: {
    loadSchedule(id) {
      this.scheduleId = id;
    },
    close() {
      this.$emit('close');
    },
    edit(item) {
      this.data = item;
    },
    cancel() {
      this.data = {};
      this.$refs.modalItem.clearSelection();
    },
    save(id) {
      this.data = {};
      this.$refs.modalItem.load(id);
    },
  },
};
</script>

<style lang="scss">
  @import '../../../../../assets/scss/variables';
  .waiting-list-modal {
  }
</style>
