<template>
  <div class="waiting-list-items">
    <div class="empty mt-2" v-if="items.length === 0">
      <div class="empty-icon">
        <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
      </div>
      <p class="empty-title h6">Lista de espera</p>
      <p class="empty-subtitle" v-if="scheduleId">
        Nenhum paciente registrado em lista de espera para esta agenda
      </p>
      <p class="empty-subtitle" v-else>
        Selecione uma agenda para visualizar os pacientes em espera
      </p>
    </div>
    <table class="table" v-else>
      <thead>
      <tr>
        <th width="30px">#</th>
        <th>Agenda / profissional</th>
        <th>Paciente</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, i) in items" :key="i"
          :class="item.editing ? 'bg-editing' : ''">
        <td>{{ i + 1 }}</td>
        <td class="text-ellipsis">
          <div>{{ item.schedule.name }}</div>
          <div><small>{{ item.professional.name }}</small></div>
          <div>
            <small class="text-info">
              Incluído em {{ item.date | date('DD/MM/YY HH:mm') }}
            </small>
          </div>
          <div>
            <small class="text-warning">
              {{ item.notes && item.notes.length > 50
                ? `${item.notes.substr(0, 50)}...`
                : item.notes
              }}
            </small>
          </div>
        </td>
        <td class="text-ellipsis">
          <div>{{ item.patient.name }}</div>
          <div><small>{{ item.insurance.name }}</small></div>
          <div>
            <small v-if="item.patient.cellphone">
              {{ item.patient.cellphone | phone }}
            </small>
            <small v-else>{{ item.patient.phone | phone }}</small>
          </div>
        </td>
        <td class="text-right">
          <button class="btn btn-sm btn-gray btn-icon btn-action mr-1"
                  :disabled="deleting || item.editing"
                  @click="edit(item)">
            <fa-icon :icon="['fal', 'pencil']"/>
          </button>
          <button class="btn btn-sm btn-error btn-icon btn-action"
                  :class="{loading: deleting}"
                  @click="remove(item.id, i)"
                  :disabled="deleting || item.editing">
            <fa-icon :icon="['fal', 'times']"/>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  props: {
    scheduleId: {
      type: String,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {
      path: '/waiting-list',
      deleting: false,
      items: [],
    };
  },
  watch: {
    scheduleId: function loadSchedule() {
      this.items = [];
      if (this.scheduleId) {
        this.load(this.scheduleId);
      }
    },
    item: function loadItem() {
      const found = this.items.find(({ id }) => id === this.item.id);
      if (found) {
        found.editing = false;
        found.schedule = this.item.schedule;
        found.professional = this.item.professional;
        found.patient = {
          id: this.item.patient.id || null,
          name: this.item.patient.name,
          phone: this.item.patient.phone,
          cellphone: this.item.patient.cellphone,
        };
        found.insurance = this.item.insurance;
        found.notes = this.item.notes;
      } else {
        this.items.push(this.item);
      }
    },
  },
  methods: {
    load(id) {
      const params = {
        limit: 0,
        scheduleId: id,
      };
      return this.$http
        .get(this.path, { params })
        .then(({ data }) => {
          this.items = data.items.map((item) => {
            // eslint-disable-next-line
            item.editing = false;
            return item;
          });
        })
        .catch(() => {});
    },
    edit(item) {
      this.clearSelection();
      item.editing = true;
      this.$emit('edit', item);
    },
    remove(id, i) {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este registro?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.deleting = true;
              this.$http
                .delete(`${this.path}/${id}`)
                .then(() => {
                  this.items.splice(i, 1);
                })
                .catch(() => {
                })
                .then(() => {
                  this.deleting = false;
                  close();
                });
            },
          },
        ],
      });
    },
    clearSelection() {
      this.items = this.items.map((item) => {
        // eslint-disable-next-line
        item.editing = false;
        return item;
      });
    },
  },
};
</script>

<style lang="scss">
  @import '../../../../../assets/scss/variables';
  .waiting-list-items {
    .bg-editing {
      background: lighten($highlight-color, 8%);
    }
  }
</style>
