<template>
  <dx-modal :title="`Replicar agendamento - ${patient.name}`" size="lg"
            :value="show" @input="close">
    <div class="modal-replicate-appointment">
      <div class="columns">
        <div class="column form-group"
             :class="{'has-error': $v.filters.scheduleId.$error}">
          <label for="replicate-schedule" class="form-label">Agenda</label>
          <select id="replicate-schedule" class="form-select"
                  @change="loadProfessionals"
                  v-model="filters.scheduleId">
            <option value="">[Selecione a agenda]</option>
            <option v-for="item in schedules"
                    :value="item.id" :key="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="column col-5 form-group"
             :class="{'has-error': $v.filters.professionalKey.$error}">
          <label for="replicate-professional" class="form-label">Profissional</label>
          <select id="replicate-professional" class="form-select"
                  v-model="filters.professionalKey">
            <option value="">[Selecione o profissional]</option>
            <option
              v-for="item in professionals"
              :value="item.key"
              :key="item.key">
              {{ item.name }}
              <template v-if="item.specialty">({{ item.specialty.name }})</template>
            </option>
          </select>
        </div>
        <div class="column col-2 form-group"
             :class="{'has-error': $v.filters.period.$error}">
          <label for="replicate-period" class="form-label">Período</label>
          <select id="replicate-period" class="form-select"
                  v-model="filters.period">
            <option value="7">Próximos 7 dias</option>
            <option value="15">Próximos 15 dias</option>
            <option value="30">Próximos 30 dias</option>
            <option value="45">Próximos 45 dias</option>
            <option value="60">Próximos 60 dias</option>
            <option value="75">Próximos 75 dias</option>
            <option value="90">Próximos 90 dias</option>
            <option value="120">Próximos 120 dias</option>
            <option value="180">Próximos 180 dias</option>
            <option value="240">Próximos 240 dias</option>
            <option value="360">Próximos 360 dias</option>
          </select>
        </div>
        <div class="column col-auto mb-2"
             style="display: flex; align-items: flex-end">
          <button class="btn btn-gray btn-icon btn-icon-left input-group-btn"
                  :class="{loading}"
                  @click="loadAppointments" :disabled="loading">
            <fa-icon :icon="['fal', 'search']"/> Pesquisar
          </button>
        </div>
        <div class="column col-12 form-group">
          <label class="form-switch mt-2">
            <input type="checkbox" v-model="filters.allowFitIn" @change="loadAppointments"/>
            <i class="form-icon"/> Exibir horários agendados
          </label>
        </div>
      </div>
      <div class="divider" />
      <div class="columns">
        <div class="column col-7 appointment-list">
          <div class="empty mt-2" v-if="appointments.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
            </div>
            <p class="empty-title h6">Agendamentos</p>
            <p class="empty-subtitle">
              Nenhum horário disponível no período informado
            </p>
          </div>
          <div class="scroll-list-wrapper">
            <div class="scroll-list">
              <div class="replicate-items" v-for="(schedule, i) in appointments" :key="i">
                <div class="text-bold">
                  {{ schedule.date | date('DD[ de ]MMMM[ de ]YYYY[ (]dddd[)]') }}
                </div>
                <div class="hour-item">
                  <button class="btn hour-item-hours tooltip"
                          :class="getScheduleColor(item)"
                          v-for="(item, h) in schedule.hours" :key="h"
                          :disabled="loading || item.selected"
                          :data-tooltip="getScheduleTooltip(item)"
                          @click="selectHour(schedule, item)">
                    {{ item.hour }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column col-5">
          <div class="empty mt-2" v-if="selectedItems.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
            </div>
            <p class="empty-title h6">Selecionados</p>
            <p class="empty-subtitle">
              Nenhum horário selecionado
            </p>
          </div>
          <table class="table" v-else>
            <thead>
            <tr>
              <th class="text-center" width="30px">#</th>
              <th class="text-center">Data Hora</th>
              <th>Profissional</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in selectedItems" :key="i">
              <td class="text-center">{{ i + 1 }}</td>
              <td class="text-center">{{ item.date | date('DD/MM/YYYY HH:mm') }}</td>
              <td>{{ item.professional.name }}</td>
              <td class="text-right">
                <button class="btn btn-sm btn-icon btn-action btn-error"
                        @click="removeHour(item, i)">
                  <fa-icon :icon="['fal', 'times']" />
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <template slot="footer">
      <button class="btn btn-primary mr-1"
              v-if="selectedItems.length > 0"
              :class="{loading: saving}"
              :disabled="saving"
              @click="save">
        Salvar agendamentos
      </button>
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
    },
    id: {
      type: String,
    },
    patient: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      filters: {
        allowFitIn: false,
        period: '7',
        scheduleId: '',
        professionalKey: '',
      },
      schedules: [],
      professionals: [],
      appointments: [],
      selectedItems: [],
    };
  },
  async mounted() {
    await this.loadSchedules();
    await this.setData();
  },
  validations() {
    return {
      filters: {
        period: { required },
        scheduleId: { required },
        professionalKey: { required },
      },
    };
  },
  computed: {
    ...mapState({
      appointmentData: state => state.appointment.form,
    }),
  },
  methods: {
    loadSchedules() {
      const params = {
        limit: 0,
        active: true,
      };

      this.schedules = [];

      return this.$http.get('/schedules', { params })
        .then(({ data }) => {
          this.schedules = data.items;
        })
        .catch(() => {});
    },
    loadProfessionals() {
      this.professionals = [];
      this.filters.professionalKey = '';

      if (!this.filters.scheduleId) {
        return null;
      }

      return this.$http.get(`/schedules/${this.filters.scheduleId}/professionals`)
        .then(({ data }) => {
          data.items.forEach((professional) => {
            professional.specialties
              .forEach((specialty) => {
                this.professionals.push({
                  key: `${professional.id}-${specialty.code}`,
                  id: professional.id,
                  name: professional.name,
                  specialty,
                });
              });
          });
        })
        .catch(() => {});
    },
    loadAppointments() {
      this.$v.filters.$touch();
      if (this.$v.filters.$error) {
        return null;
      }

      this.loading = true;
      this.appointments = [];

      const professional = this.professionals
        .find(({ key }) => key === this.filters.professionalKey);

      const params = {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(this.filters.period, 'days').format('YYYY-MM-DD'),
        scheduleId: this.filters.scheduleId,
        professionalIds: professional.id,
      };

      return this.$http
        .get('/calendar', { params })
        .then(({ data: result }) => {
          if (result.items && result.items.length > 0) {
            let items;
            if (this.filters.allowFitIn) {
              items = result.items
                .filter(item => (
                  (!item.isArrivalOrder
                    && (
                      item.status === 'available'
                      || item.status === 'scheduled'
                      || item.status === 'confirmed'
                      || item.status === 'in_attendance'
                      || item.status === 'waiting'
                      || item.status === 'finished'
                      || item.status === 'report'
                      || item.status === 'payment'
                      || item.status === 'screening'
                    )
                  )));

              if (items && items.length > 0) {
                // Horários no qual o paciente a ser replicado já está agendado
                const patientSchedules = items.filter(item => (item.patient
                  && item.patient.name === this.appointmentData.patient.name));

                if (patientSchedules && patientSchedules.length > 0) {
                  patientSchedules.forEach((patientSchedule) => {
                    // Todos os pacientes que estão agendados nos horários no qual o paciente
                    // está agendado
                    const schedules = items
                      .filter(item => (item.startDate === patientSchedule.startDate));
                    schedules.forEach((item) => {
                      const idx = items.indexOf(item);
                      items.splice(idx, 1);
                    });
                  });
                }
              }
            } else {
              items = result.items
                .filter(item => (
                  (!item.isArrivalOrder && item.status === 'available'
                  )));
            }

            this.appointments = items
              .reduce((results, item) => {
                const startDate = item.startDate.substr(0, 10);
                const startHour = item.startDate.substr(11, 5);

                const found = this.appointments.find(({ date }) => date === startDate);

                const scheduleSelected = this.selectedItems
                  .find(selectedItem => selectedItem.date === `${startDate} ${startHour}`);

                const hourData = {
                  selected: !!scheduleSelected,
                  status: item.status,
                  patients: item.status === 'available' ? 0 : 1,
                  hour: startHour,
                };

                if (!found) {
                  this.appointments.push({
                    date: startDate,
                    scheduleId: item.scheduleId,
                    professional,
                    specialty: professional.specialty,
                    hours: [hourData],
                  });
                } else {
                  const foundHour = found.hours.filter(hourItem => hourItem.hour === startHour);
                  if (foundHour && foundHour.length > 0) {
                    foundHour[0].patients += 1;
                  } else {
                    found.hours.push(hourData);
                  }
                }

                return this.appointments;
              }, []);
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    async setData() {
      if (this.appointmentData.scheduleId) {
        this.filters.scheduleId = this.appointmentData.scheduleId;
        await this.loadProfessionals();
        if (this.appointmentData.professional) {
          const professional = this.appointmentData.professional.id;
          const { specialty } = this.appointmentData.professional;
          this.filters.professionalKey = `${professional}-${specialty}`;
        }
        if (this.filters.scheduleId && this.filters.professionalKey) {
          this.loadAppointments();
        }
      }
    },
    selectHour(schedule, item) {
      this.selectedItems.push({
        date: `${schedule.date} ${item.hour}`,
        scheduleId: schedule.scheduleId,
        professional: schedule.professional,
        slot: item.patients !== 0,
      });
      item.selected = true;
    },
    removeHour(item, idx) {
      const itemDate = item.date.substr(0, 10);
      const itemHour = item.date.substr(11, 5);

      const foundAppointment = this.appointments
        .find(appointment => (
          appointment.professional.id === item.professional.id
          && appointment.date === itemDate
        ));

      const foundHour = foundAppointment.hours
        .find(({ hour }) => hour === itemHour);

      if (foundHour) {
        foundHour.selected = false;
      }

      this.selectedItems.splice(idx, 1);
    },
    close() {
      this.$emit('close');
    },
    save() {
      if (this.selectedItems.length === 0) {
        return;
      }

      const data = {
        appointmentId: this.id,
        items: this.selectedItems
          .map(item => ({
            startDate: item.date,
            endDate: item.date,
            scheduleId: item.scheduleId,
            professionalId: item.professional.id,
            specialty: item.professional.specialty
              ? item.professional.specialty.code
              : null,
            slot: item.slot,
          })),
      };

      this.saving = true;

      this.$http.post('/multiple-appointments', data)
        .then(() => {
          this.appointments = [];
          this.selectedItems = [];
          this.close();
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    getScheduleColor(item) {
      if (item.status === 'available') return item.selected ? 'bg-warning' : 'bg-secondary';
      return item.selected ? 'bg-warning' : 'bg-error';
    },
    getScheduleTooltip(item) {
      if (item.patients === 0) return 'Horário livre';
      if (item.patients === 1) return '1 paciente';
      return `${item.patients} pacientes`;
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
@import '~assets/scss/mixins';
.modal-replicate-appointment {
  .appointment-list {
    display: flex;
    flex-direction: column;
    height: 21.3rem;
    overflow-y: auto;
    .scroll-list-wrapper {
      flex-grow: 1;
      position: relative;
    }
    .scroll-list {
      bottom: 0;
      overflow-y: auto;
      padding-right: $layout-spacing;
      position: absolute;
      top: 0;
      width: 100%;
      @include scroll-bar();
    }
  }
  .replicate-items {
    margin-bottom: $layout-spacing;
    .hour-item {
      background-color: $gray-color-ultra-light;
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      margin-top: $layout-spacing-sm;
      padding: $layout-spacing;
      .hour-item-hours {
        border: 0;
        color: $light-color;
        cursor: pointer;
        font-size: $font-size-sm;
        height: auto;
        margin: $layout-spacing-sm;
        width: 3rem;
      }
    }
  }
}
</style>
