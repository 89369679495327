<template>
  <div class="appointments-side-page">
    <div class="columns">
      <div class="column col-12 form-group">
        <label class="form-label">Procedimento</label>
        <select id="specialty" class="form-select" v-model="filter.expenseId">
          <option value="">[Todos]</option>
          <option v-for="item in expenses"
                  :value="item.key" :key="item.key">{{ item.name }}</option>
        </select>
      </div>
      <div class="column col-12 form-group">
        <label class="form-label">Convênio</label>
        <select id="insurance" class="form-select" v-model="filter.insuranceId">
          <option value="">[Todos]</option>
          <option v-for="(item, i) in filter.insurances"
                  :value="item.id" :key="i">{{ item.customName }}</option>
        </select>
      </div>
      <div class="column col-12 form-group">
        <label class="form-label">Agenda</label>
        <select id="professional" class="form-select" v-model="filter.scheduleId">
          <option value="">[Todos]</option>
          <option v-for="(item, i) in filter.schedules"
                  :value="item.id" :key="i">{{ item.name }}</option>
        </select>
      </div>
      <div class="column col-12 form-group">
        <st-calendar
          :date="dateJS"
          :highlights="highlights"
          @select="selectDate"
          @month-change="monthChange"
          ref="calendar">
        </st-calendar>
      </div>
    </div>
    <div class="columns mt-2 btn-actions-group">
      <div class="column col-12 mb-2">
        <button class="btn btn-gray btn-block"
                data-tooltip="Voltar para hoje"
                @click="$bus.$emit('appointment-today')">
          Voltar para hoje
        </button>
      </div>
      <div class="column col-12 mb-2">
        <button class="btn btn-primary btn-block"
                @click="openPatientAppointment">Reagendar paciente</button>
      </div>
      <div class="column col-12">
        <button class="btn btn-secondary btn-block"
                @click="openSearch">Pesquisar agendamento</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapMutations, mapActions, mapState,
} from 'vuex';
import moment from 'moment';
import localforage from 'localforage';

export default {
  data() {
    return {
      showPrint: false,
      filter: this.$store.state.appointment.filter,
    };
  },
  beforeCreate() {
    const appointmentState = this.$store.state.appointment;
    appointmentState.filter = appointmentState.blankFilter();
    appointmentState.currentMonth = null;
  },
  async mounted() {
    if (this.user.branch.settings && !this.user.branch.settings.canViewSchedule) {
      this.filter.professionalId = this.user.id;
    }
    if ('date' in this.$route.query && this.$route.query.date) {
      this.filter.date = this.$route.query.date;
    }
    this.$bus.$on('appointment-today', () => this.today());
    this.loadFilters();
    await this.loadFilterState();
    this.loadCalendar(true)
      .then(this.setWatchers);
  },
  methods: {
    setWatchers() {
      this.$watch('filter.date', () => this.loadCalendar(false));
      this.$watch('filter.expenseId', () => this.loadCalendar(true));
      this.$watch('filter.insuranceId', () => this.loadCalendar(true));
      this.$watch('filter.scheduleId', () => this.loadCalendar(true));
    },
    loadFilterState() {
      return localforage.getItem('appointmentFilters')
        .then((filters) => {
          if (filters) {
            if (filters.insuranceId) {
              this.filter.insuranceId = filters.insuranceId;
            }
            if (filters.expenseId) {
              this.filter.expenseId = filters.expenseId;
              if (filters.specialtyCode) {
                this.filter.expenseId = [filters.expenseId, filters.specialtyCode].join(':');
              }
            }
            if (filters.scheduleId) {
              this.filter.scheduleId = filters.scheduleId;
            }
          }
        })
        .catch(() => {});
    },
    today() {
      this.filter.date = moment().format('YYYY-MM-DD');
    },
    selectDate({ dateISO }) {
      this.filter.date = dateISO;
    },
    monthChange({ dateISO }) {
      this.setCurrentMonth(dateISO);
      this.loadAvailableDays();
    },
    ...mapMutations({
      showAppointmentModal: 'Appointment.SHOW_APPOINTMENT_MODAL',
      openSearch: 'Appointment.SHOW_SEARCH_APPOINTMENT_MODAL',
      openPatientAppointment: 'Appointment.SHOW_PATIENT_APPOINTMENT_MODAL',
      openPrint: 'Appointment.SHOW_PRINT_APPOINTMENT_MODAL',
      openCommitment: 'Appointment.SHOW_COMMITMENT_APPOINTMENT_MODAL',
      setCurrentMonth: 'Appointment.SET_CURRENT_MONTH',
    }),
    ...mapActions({
      loadCalendar: 'loadCalendar',
      loadFilters: 'loadFilters',
      loadAvailableDays: 'loadAvailableDays',
    }),
  },
  computed: {
    ...mapState({
      user: ({ auth }) => auth.user,
      highlights: state => state.appointment.available.days.map(item => ({
        date: item.date,
        label: `${item.count} ${item.count === 1 ? 'horário' : 'horários'}`,
      })),
    }),
    expenses() {
      return this.filter.expenses.reduce((result, item) => {
        if (item.specialties.length > 0) {
          item.specialties.forEach((specialty) => {
            result.push({
              ...item,
              name: `${item.name} (${specialty.name})`,
              key: [item.id, specialty.code].join(':'),
            });
          });
        } else {
          result.push({ ...item, key: item.id });
        }
        return result;
      }, []);
    },
    dateJS() {
      return moment(this.filter.date).toDate();
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/scss/variables';
  .appointments-side-page {
    padding: $layout-spacing;
    .form-group {
      padding-top: $layout-spacing + .05rem;
      position: relative;
      .form-label {
        background-color: $light-color;
        border-radius: $border-radius;
        color: $gray-color;
        left: $layout-spacing + .25rem;
        font-size: $font-size-xs;
        line-height: normal;
        padding: $layout-spacing-sm / 2 $layout-spacing-sm;
        position: absolute;
        top: 0;
      }
    }
  }
</style>
